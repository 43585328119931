import React, { useState } from 'react';
import { useCartServices } from '../actions/cart-services/index';

export default function OrderActions({
  canAddToBasket,
  lineItemId,
  isItemClone,
  isAttendant,
  ...props
}) {
  const attendantCustomerId = new URLSearchParams(window.location.search).get('user_id');

  const [buttonWasPressed, setButtonWasPressed] = useState(false);
  const [attendantToken, setAttendantToken] = useState(null);

  const [handleSubmit, loading] = useCartServices({ lineItemId, isItemClone, isAttendant, attendantToken, attendantCustomerId, ...props });

  const pressHandler = () => {
    if (window.dataLayer && !lineItemId) {
      gtag('event', 'add_to_basket', {
        params: JSON.stringify({ ...props.userSelections, ...props.quantity })
      });
    }
    if (window.dataLayer && lineItemId && isItemClone) {
      gtag('event', 're_order_modified', {
        modified_line_item_id: lineItemId,
        params: JSON.stringify({ ...props.userSelections, ...props.quantity })
      });
    }
    setButtonWasPressed(true);
    handleSubmit();
  };

  React.useEffect(() => {
    if(isAttendant) {
      const url = window.location.pathname;
      const segments = url.split('/');
      setAttendantToken(segments[2]);
    }
  }, [isAttendant]);

  return (
    <div>
      <div className="row no-gutters fc-actions-row">
        <div className="col-md-6 col-sm-12 fc-add-to-cart">
          <button
            className="btn btn-default btn-block btn-add-to-cart"
            onClick={pressHandler}
            disabled={buttonWasPressed || !canAddToBasket}
          >
            {loading ? 'LOADING' : lineItemId ? 'SAVE CHANGES' : 'ADD TO BASKET' }
          </button>
        </div>
      </div>
    </div>
  );
}
