// Run this example by adding <%= javascript_pack_tag 'Configurator_react' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>Configurator React</div> at the bottom
// of the page.

import React, { useEffect, useMemo } from 'react';
import ModelImagePreview from './ModelImagePreview';
import AttributesImagePreview from './AttributesImagePreview';
import FitImagePreview from './FitImagePreview';
import BodySelectionPanel from './BodySelectionPanel';
import FabricSelectionPanel from './FabricSelectionPanel';
import MonogramSelectionPanel from './MonogramSelectionPanel';
import FabricDescriptionModal from './FabricDescriptionModal';
import AttributeSelectionPanel from './AttributeSelectionPanel';
import ContrastStitchingSelectionPanel from './ContrastStitchingSelectionPanel';
import NextStepButton from './NextStepButton';
import ConfiguratorHome from './ConfiguratorHome';
import FitSelectionPanel from './FitSelectionPanel';
import {
  useProductPreferences,
  useProductOptions
} from '../actions/configurator_actions';
import {
  CONTRAST_STITCHING_NO_OPTION_GPP_CODE,
  CONTRAST_STITCHING_CATEGORIES_SET,
  CONTRAST_STITCHING_CATEGORIES_ARRAY
} from '../constants/contrast_stitching';
import { SHOW_BODY_SELECTOR } from '../constants/body_selector';
import { hasProp } from '../utils/object';
import PopupBodySelector from './PopupBodySelector';
import ButtonholeSelectionPanel from "./Buttonhole/ButtonholeSelectionPanel";

const MONOGRAM_STYLES_SPECS = {
  VC00183: {
    min: 1,
    max: 9,
    validCharacters: '-*!@#$%&+?:;)("=A-Za-z0-9 ',
    validCharactersDisplay: 'alphabetic (a-z, A-Z, 0-9, !@#$%&*+?:;)(-"=)',
    textTransform: 'uppercase',
    previewInitials: 'JMS',
    margin: 12,
    style: {
      fontFamily: 'Roboto Condensed' + ', sans-serif',
      fontWeight: 700
    },
    monogramPreviewStyle: 'monogram-style-text'
  },
  VC00496: {
    min: 3,
    max: 3,
    validCharacters: 'A-Za-z ',
    validCharactersDisplay: 'alphabetic (A-Z)',
    textTransform: 'uppercase',
    previewInitials: 'JHC',
    margin: 10,
    style: {
      fontFamily: 'Diamond Mono Center' + ', sans-serif',
      fontWeight: 2500
    },
    monogramPreviewStyle: 'diamond-monogram'
  },
  VC00693: {
    min: 1,
    max: 9,
    validCharacters: '-*!@#$%&+?:;)("=A-Za-z0-9 ',
    validCharactersDisplay: 'alphabetic (a-z, A-Z, 0-9, !@#$%&*+?:;)(-"=)',
    textTransform: 'uppercase',
    previewInitials: 'ABC',
    margin: 30,
    style: {
      fontFamily: 'Pinyon Script' + ', cursive',
      fontWeight: 400
    },
    monogramPreviewStyle: 'monogram-style-text'
  },
  VC00583: {
    min: 2,
    max: 2,
    validCharacters: 'a-z',
    validCharactersDisplay: 'alphabetic (A-Z)',
    textTransform: 'uppercase',
    previewInitials: 'Rc',
    margin: 30,
    style: {
      fontFamily: 'Pinyon Script' + ', cursive',
      fontWeight: 400
    },
    monogramPreviewStyle: 'script-initial-code'
  },
  VC08864: {
    min: 1,
    max: 8,
    validCharacters: 'A-Za-z0-9 ',
    validCharactersDisplay: 'alphanumeric (a-z, A-Z, 0-9)',
    textTransform: 'none',
    previewInitials: 'ABC',
    margin: 12,
    style: {
      fontFamily: 'Stardos Stencil' + ', serif',
      fontWeight: 400
    },
    monogramPreviewStyle: 'monogram-style-text'
  },
  VC08866: {
    min: 1,
    max: 8,
    validCharacters: 'A-Za-z0-9 ',
    validCharactersDisplay: 'alphanumeric (a-z, A-Z, 0-9)',
    textTransform: 'none',
    previewInitials: 'Aa1',
    margin: 12,
    style: {
      fontFamily: 'Source_Sans_3' + ', sans-serif',
      fontWeight: 700
    },
    monogramPreviewStyle: 'monogram-style-text'
  },
  VC08867: {
    min: 1,
    max: 8,
    validCharacters: 'A-Za-z0-9 ',
    validCharactersDisplay: 'alphanumeric (a-z, A-Z, 0-9)',
    textTransform: 'none',
    previewInitials: 'Aa1',
    margin: 12,
    style: {
      fontFamily: 'Pinyon Script' + ', cursive',
      fontWeight: 400
    },
    monogramPreviewStyle: 'monogram-style-text'
  },
  VC23091: {
    min: 1,
    max: 3,
    validCharacters: 'A-Za-z ',
    validCharactersDisplay: 'alphabetic (A-Z)',
    textTransform: 'uppercase',
    previewInitials: 'ABC',
    margin: 12,
    style: {
      fontFamily: 'DM Serif Display' + ', serif',
      fontWeight: 400
    },
    monogramPreviewStyle: 'monogram-style-text'
  },
  VC23072: {
    min: 1,
    max: 3,
    validCharacters: 'A-Za-z ',
    validCharactersDisplay: 'alphabetic (A-Z)',
    textTransform: 'uppercase',
    previewInitials: 'ABC',
    margin: 12,
    style: {
      fontFamily: 'Roboto Condensed' + ', sans-serif',
      fontWeight: 700
    },
    monogramPreviewStyle: 'monogram-style-text'
  },
  VC25510: {
    min: 1,
    max: 16,
    validCharacters: '-*!@#$%&+?:;)("<>A-Za-z0-9 ',
    validCharactersDisplay: 'alphabetic (a-z, A-Z, 0-9, !@#$%&*+?:;)(-"<>)',
    textTransform: 'none',
    previewInitials: 'Abc',
    margin: 12,
    style: {
      fontFamily: 'Roboto Condensed' + ', sans-serif',
      fontWeight: 400
    },
    monogramPreviewStyle: 'monogram-style-text'
  },
  VC25511: {
    min: 1,
    max: 16,
    validCharacters: '-*!@#$%&+?:;)("<>A-Za-z0-9 ',
    validCharactersDisplay: 'alphabetic (a-z, A-Z, 0-9, !@#$%&*+?:;)(-"<>)',
    textTransform: 'none',
    previewInitials: 'Abc',
    margin: 12,
    style: {
      fontFamily: 'Pinyon Script' + ', cursive',
      fontWeight: 400
    },
    monogramPreviewStyle: 'monogram-style-text'
  },
  VC24519: {
    min: 1,
    max: 16,
    validCharacters: 'A-Za-z ',
    validCharactersDisplay: 'alphabetic (A-Z)',
    textTransform: 'none',
    previewInitials: 'Abc',
    margin: 12,
    style: {
      fontFamily: 'Roboto Condensed' + ', sans-serif',
      fontWeight: 400
    },
    monogramPreviewStyle: 'monogram-style-text'
  },
  VC24520: {
    min: 1,
    max: 16,
    validCharacters: 'A-Za-z ',
    validCharactersDisplay: 'alphabetic (A-Z)',
    textTransform: 'none',
    previewInitials: 'Abc',
    margin: 12,
    style: {
      fontFamily: 'Pinyon Script' + ', cursive',
      fontWeight: 400
    },
    monogramPreviewStyle: 'monogram-style-text'
  },
  default: {
    min: 0,
    max: 0,
    validCharacters: '',
    validCharactersDisplay: '',
    textTransform: 'none',
    previewInitials: 'Aa1',
    margin: 12,
    style: {
      fontFamily: 'Pinyon Script' + ', cursive',
      fontWeight: 400
    },
    monogramPreviewStyle: 'monogram-style-text'
  }
};

const OPTION_FILTERS = {
  monogram_location: {
    VC00394: (userSelections) =>
      // 'Center Pocket' is not an option when "no pocket" is selected
      userSelections.pocket?.gpp_code !== 'VC00052',
    VC00678: (userSelections) =>
      // 'Top Pocket Hem' is not an option when "no pocket" is selected
      userSelections.pocket?.gpp_code !== 'VC00052' &&
      userSelections.monogram_style?.gpp_code !== 'VC00496' &&
      userSelections.monogram_style?.gpp_code !== 'VC00583',
    VC00171: (userSelections) =>
      // 'Left Breast' is only an option when "no pocket" is selected (or no pocket selection has been made)
      userSelections.pocket?.gpp_code === 'VC00052' || !userSelections.pocket,
    VC22536: (userSelections) =>
      // 'Inside Collar Band' is not an option when monogram style "MOH" is selected
      userSelections.monogram_style?.gpp_code !== 'VC00496' &&
      userSelections.monogram_style?.gpp_code !== 'VC00583'
  },
  contrast_stitching_color: {
    ...CONTRAST_STITCHING_NO_OPTION_GPP_CODE.contrast_stitching_color.reduce(
      (a, gpp_code) => {
        a[gpp_code] = () => false;
        return a;
      },
      {}
    )
  },
  contrast_stitching_location: {
    ...CONTRAST_STITCHING_NO_OPTION_GPP_CODE.contrast_stitching_location.reduce(
      (a, gpp_code) => {
        a[gpp_code] = () => false;
        return a;
      },
      {}
    )
  }
};

const UNHIDDABLE_CATEGORIES = new Set(['fabric']);

const REQUIRED_MONOGRAM_CATEGORIES = new Set([
  'monogram_initials',
  'monogram_style'
]);

const CUSTOM_NEXT_ATTRIBUTE_NAME = {
  monogram: 'Monogram',
  contrast_stitching: 'Contrast Stitching'
};

export default function Configurator({
  productKey,
  productName,
  productExternalId,
  hasLaydownPreview,
  existingBody,
  confirmedFitShapeCode,
  existingLineItemOptions,
  existingMonogramInitials,
  startingQuantity,
  authenticityToken,
  remoteImagesPath,
  resizedRemoteImagesPath,
  resizedImageExtension,
  categorySettings,
  cartSettings,
  currencyCode,
  hidePayment,
  bodySelectorIframeUrl,
  showBodySelector = SHOW_BODY_SELECTOR.NONE,
  fabricDisplayValue,
  brandCode,
  showCustomPreviewImage = false,
  showFabricDetail,
  isIframeIntegrated = false,
  isItemClone = false,
  isAttendant = false
}) {
  const [currentScreen, setCurrentScreen] = React.useState('main');
  const [existingLineItems, setExistingLineItems] = React.useState(
    existingLineItemOptions
  );
  const [contrastStitchingEnabled, setContrastStitchingEnabled] =
    React.useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const view = queryParams.get('view');
  let essQueryParams = {};
  if (queryParams.has('formcut_email') && queryParams.has('formcut_scan_id')) {
    essQueryParams = {
      formcut_email: queryParams.get('formcut_email'),
      formcut_scan_id: queryParams.get('formcut_scan_id')
    };
  }

  const popupQueryParamConfiguredItemIds = (
    queryParams.get('configured_item_ids') || ''
  ).split(',');

  // We need monogram_style regardless of if its hidden or not because the min max character for monogram initials
  // depends on the style
  const categories = useMemo(
    () =>
      Object.keys(categorySettings).filter(
        (c) => c === 'monogram_style' || categorySettings[c]?.hidden === false
      ),
    [categorySettings]
  );

  const categoriesWithoutPreview = Object.keys(categorySettings).filter(
    (c) => categorySettings[c]?.has_laydown_preview === false
  );
  let currencySymbol;
  if (currencyCode === 'GBP') {
    currencySymbol = '£';
  } else if (currencyCode === 'HKD') {
    currencySymbol = 'HK$';
  } else {
    currencySymbol = '$';
  }

  const defaultSelectedBody = useMemo(() => {
    if (existingBody?.email) return existingBody;

    let localStorageBodyData = localStorage.getItem('selectedBody');

    if (localStorageBodyData) {
      try {
        localStorageBodyData = JSON.parse(localStorageBodyData);
      } catch {
        localStorageBodyData = undefined;
      }
    }
    if (localStorageBodyData) {
      return localStorageBodyData;
    }

    const currentExternalCartId = new URLSearchParams(
      window.location.search
    ).get('externalCartId');
    if (!currentExternalCartId) return {};

    const previousExternalCartId = localStorage.getItem('externalCartId');
    if (previousExternalCartId === currentExternalCartId) {
      return JSON.parse(localStorage.getItem('selectedBody')) || {};
    } else {
      localStorage.setItem('externalCartId', currentExternalCartId);
      return {};
    }
  }, [existingBody]);

  const [selectedBody, setSelectedBody] = React.useState(defaultSelectedBody);
  const updateSelectedBody = (body) => {
    setSelectedBody(body);
    // In case of iframe-integration, the user body data is managed by ESS
    if (!isIframeIntegrated) {
      localStorage.setItem('selectedBody', JSON.stringify(body));
    }
  };

  const fitOptions = useProductPreferences(productKey).data;
  const [selectedFit, setSelectedFit] = React.useState(null);

  useLoadExistingFit(
    fitOptions,
    existingLineItems.fit || confirmedFitShapeCode,
    setSelectedFit
  );

  const defaultUserSelection = useMemo(() => {
    const initialUserSelections = {};
    categories.forEach((category) => {
      initialUserSelections[category] = null;
    });
    return initialUserSelections;
  }, [categories]);

  const [userSelections, setUserSelections] =
    React.useState(defaultUserSelection);

  const setSelectedAttributeFunctions = categories.reduce(
    (obj, category) => (
      (obj[category] = (newAttributeValue) => {
        setUserSelections((us) => ({ ...us, [category]: newAttributeValue }));
      }),
      obj
    ),
    {}
  );

  const [wasInMonogramSection, setWasInMonogramSection] = React.useState(false);
  const [wasInContrastStitchingSection, setWasInContrastStitchingSection] =
    React.useState(false);

  const productOptions = useProductOptions({
    categories,
    productKey,
    fitOptions,
    existingLineItemId: existingLineItems.id,
    fabricDisplayValue
  });
  let unselectableOptionsTable = {};

  const selectableOptionsTable =
    (Object.keys(productOptions).length &&
      Object.entries(productOptions[selectedFit?.gpp_shape_code] ?? {}).reduce(
        (acc, [key, options]) => {
          if (hasProp(OPTION_FILTERS, key)) {
            acc[key] = [];

            for (const option of options) {
              if (
                OPTION_FILTERS[key][option.gpp_code]?.(userSelections) ??
                true
              ) {
                acc[key].push(option);
              } else {
                unselectableOptionsTable[key] = [
                  ...(unselectableOptionsTable[key] || []),
                  option
                ];
              }
            }
          } else {
            acc[key] = options;
          }

          return acc;
        },
        {}
      )) ||
    defaultUserSelection;

  useEffect(() => {
    const selections = categories.reduce((obj, category) => {
      // Look/check for userSelection in selectable options
      let selection = selectableOptionsTable[category]?.find(
        (attrOption) => attrOption.id === userSelections[category]?.id
      );

      // Else try to restore existing line item options
      // except for monograms and contrast stitching panel
      if (
        !selection &&
        !(wasInMonogramSection && monogramCategories.includes(category)) &&
        !(
          wasInContrastStitchingSection &&
          CONTRAST_STITCHING_CATEGORIES_SET.has(category)
        )
      ) {
        selection = selectableOptionsTable[category]?.find(
          (attrOption) => attrOption.id === existingLineItems[category]
        );
      }

      // Only for contrast stitching categories:
      // Check if the userSelection is the None option
      // None options aren't present in selectableOptionsTable
      if (!selection && CONTRAST_STITCHING_CATEGORIES_SET.has(category)) {
        selection = unselectableOptionsTable[category]?.find(
          (attrOption) => attrOption.id === userSelections[category]?.id
        );

        if (!selection && !wasInContrastStitchingSection) {
          selection = unselectableOptionsTable[category]?.find(
            (attrOption) => attrOption.id === existingLineItems[category]
          );
        }
      }

      return { ...obj, [category]: selection || null };
    }, {});

    if (JSON.stringify(selections) !== JSON.stringify(userSelections)) {
      setUserSelections(selections);

      // When restoring the selection from existing line items, set the appropriate
      // checkbox state for contrast stitching panel
      if (!wasInContrastStitchingSection) {
        // check if contrast stitching is enabled
        const didUserDisable = CONTRAST_STITCHING_CATEGORIES_ARRAY.every(
          (category) => {
            return CONTRAST_STITCHING_NO_OPTION_GPP_CODE[category].some(
              (gpp_code) => gpp_code === selections[category]?.gpp_code
            );
          }
        );

        setContrastStitchingEnabled(!didUserDisable);
      }
    }
  }, [
    selectableOptionsTable,
    wasInMonogramSection,
    wasInContrastStitchingSection
  ]);

  const selectableOptionsTableLoaded =
    Object.keys(selectableOptionsTable).length == categories.length &&
    !Object.values(selectableOptionsTable).every((x) => !x);

  const selectableOptions = selectableOptionsTable[currentScreen];
  const currentSelectedAttribute = userSelections[currentScreen];
  const currentSetSelectedAttribute =
    setSelectedAttributeFunctions[currentScreen];

  const categoriesToShow = categories.filter((category) => {
    if (!selectableOptionsTableLoaded) {
      // Show category if it's not hidden when the table hasn't loaded
      return !categorySettings[category].hidden;
    } else {
      // Show category if it has selectable options or is unhiddable
      const hasSelectableOptions =
        (selectableOptionsTable[category] &&
          selectableOptionsTable[category].length > 1 &&
          !REQUIRED_MONOGRAM_CATEGORIES.has(category)) ||
        (REQUIRED_MONOGRAM_CATEGORIES.has(category) &&
          selectableOptionsTable[category] &&
          selectableOptionsTable[category].length > 0);
      const isUnhiddable = UNHIDDABLE_CATEGORIES.has(category);
      return hasSelectableOptions || isUnhiddable;
    }
  });

  // If even preview image for a single category is unable to load, get rid of all the preview images and show the default one
  const [isDefaultImageActive, setIsDefaultImageActive] = React.useState(false);
  const triggerDefaultImage = () => {
    setIsDefaultImageActive(true);
  };

  //monogram
  const [monogramInitials, setMonogramInitials] = React.useState(
    existingMonogramInitials || ''
  );
  const monogramCategories = categoriesToShow.filter(
    (c) => categorySettings[c]?.selection_panel_layout === 'monogram'
  );
  const monogramSelections = monogramCategories.reduce((obj, key) => {
    if (userSelections[key] !== undefined) {
      obj[key] = userSelections[key];
    }
    return obj;
  }, {});

  const selectedMonogramStyleSpecs =
    MONOGRAM_STYLES_SPECS[userSelections.monogram_style?.gpp_code] ||
    (selectableOptionsTable?.monogram_style?.length ?
      {
        min: 0,
        max: Math.max(
          ...selectableOptionsTable.monogram_style.map(
            (s) => MONOGRAM_STYLES_SPECS[s.gpp_code].max
          )
        ),
        validCharacters: selectableOptionsTable.monogram_style
          .map((s) => MONOGRAM_STYLES_SPECS[s.gpp_code].validCharacters)
          .reduce((a, b) => (a.length > b.length ? a : b)),
        validCharactersDisplay: selectableOptionsTable.monogram_style
          .map((s) => MONOGRAM_STYLES_SPECS[s.gpp_code])
          .reduce((a, b) =>
            a.validCharacters.length > b.validCharacters.length ? a : b
          ).validCharactersDisplay,
        textTransform:
          selectableOptionsTable.monogram_style
            .map((s) => MONOGRAM_STYLES_SPECS[s.gpp_code].textTransform)
            .reduce((a, b) =>
              a === 'none' || b === 'none' ? 'none'
              : a === 'uppercase' || b === 'uppercase' ? 'uppercase'
              : a === 'lowercase' || b === 'lowercase' ? 'lowercase'
              : null
            ) || 'none',
        previewInitials:
          MONOGRAM_STYLES_SPECS[
            selectableOptionsTable.monogram_style[0].gpp_code
          ].previewInitials,
        margin: MONOGRAM_STYLES_SPECS.default.margin,
        style:
          MONOGRAM_STYLES_SPECS[
            selectableOptionsTable.monogram_style[0].gpp_code
          ].style,
        monogramPreviewStyle:
          MONOGRAM_STYLES_SPECS[
            selectableOptionsTable.monogram_style[0].gpp_code
          ].monogramPreviewStyle
      }
    : MONOGRAM_STYLES_SPECS.default);
  const defaultMonogramColor =
    selectableOptionsTable?.monogram_color?.length ?
      selectableOptionsTable.monogram_color.find(
        (c) => c.font_family === '#000000'
      ) || selectableOptionsTable.monogram_color[0]
    : { font_family: '#000000' };
  const monogramError =
    monogramInitials.length < selectedMonogramStyleSpecs.min ?
      `Please update your initials: the style you have selected requires at least ${
        selectedMonogramStyleSpecs.min
      } character${selectedMonogramStyleSpecs.min == 1 ? '' : 's'}`
    : monogramInitials.length > selectedMonogramStyleSpecs.max ?
      `Please update your initials: the style you have selected supports at most ${selectedMonogramStyleSpecs.max} characters`
    : (
      !new RegExp(
        `^[${selectedMonogramStyleSpecs.validCharacters}]*$`,
        'i'
      ).test(monogramInitials)
    ) ?
      `Please update your initials: the style you have selected only supports ${selectedMonogramStyleSpecs.validCharactersDisplay} characters.`
    : '';
  const monogramFullySelected =
    ((wasInMonogramSection || !!existingLineItems.id) &&
      !userSelections.monogram_initials) ||
    (!monogramError && Object.values(monogramSelections).every(Boolean));
  const transformedMonogramInitials =
    selectedMonogramStyleSpecs.textTransform === 'uppercase' ?
      monogramInitials.toUpperCase()
    : selectedMonogramStyleSpecs.textTransform === 'lowercase' ?
      monogramInitials.toLowerCase()
    : monogramInitials;

  const clearMonogram = () => {
    setMonogramInitials('');
    setUserSelections({
      ...userSelections,
      ...Object.fromEntries(
        monogramCategories.map((category) => [category, null])
      )
    });
  };

  let categoryIndex = -1;

  for (let i = 0; i < categoriesToShow.length; i++) {
    if (currentScreen === 'monogram') {
      if (categoriesToShow[i] === 'monogram_initials') {
        categoryIndex = i;
        break;
      }
    } else if (currentScreen === 'contrast_stitching') {
      if (CONTRAST_STITCHING_CATEGORIES_SET.has(categoriesToShow[i])) {
        categoryIndex = i;
        break;
      }
    } else if (categoriesToShow[i] === currentScreen) {
      categoryIndex = i;
      break;
    }
  }

  let nextAttribute = categoriesToShow[categoryIndex + 1];
  let previousAttribute = categoriesToShow[categoryIndex - 1];
  if (categoryIndex == 0 && !confirmedFitShapeCode) previousAttribute = 'fit';

  // Monogram options are handled on their own screen.
  if (currentScreen === 'monogram') {
    for (
      let indexOffset = 2;
      monogramCategories.includes(nextAttribute);
      indexOffset++
    ) {
      nextAttribute = categoriesToShow[categoryIndex + indexOffset];
    }
  } else if (currentScreen === 'contrast_stitching') {
    if (nextAttribute !== 'monogram') {
      for (
        let indexOffset = 1;
        CONTRAST_STITCHING_CATEGORIES_SET.has(nextAttribute);
        indexOffset++
      ) {
        nextAttribute = categoriesToShow[categoryIndex + indexOffset];
      }
    }
  }

  if (nextAttribute) {
    if (CONTRAST_STITCHING_CATEGORIES_SET.has(nextAttribute))
      nextAttribute = 'contrast_stitching';
    else if (monogramCategories.includes(nextAttribute))
      nextAttribute = 'monogram';
  }

  if (previousAttribute) {
    if (CONTRAST_STITCHING_CATEGORIES_SET.has(previousAttribute))
      previousAttribute = 'contrast_stitching';
    else if (monogramCategories.includes(previousAttribute))
      previousAttribute = 'monogram';
  }

  const nextAttributeName =
    CUSTOM_NEXT_ATTRIBUTE_NAME[nextAttribute] ||
    categorySettings[nextAttribute]?.display_name;

  const canAddToBasket = !!(
    (cartSettings.cart === 'iframePostMessage' || selectedBody.scanId) &&
    selectedFit &&
    categoriesToShow.every(
      (category) =>
        monogramCategories.includes(category) ||
        userSelections[category] !== null
    ) &&
    monogramFullySelected
  );

  const canAddToAttendantBasket = !!(
    selectedFit &&
    categoriesToShow.every(
      (category) =>
        monogramCategories.includes(category) ||
        userSelections[category] !== null
    ) &&
    monogramFullySelected &&
    isAttendant
  );

  let screenToDisplay;
  let mobileNextStepsButton;
  switch (currentScreen) {
    case 'main': {
      const pricedSelections = Object.values(userSelections);
      const itemCost = pricedSelections
        .map((selection) => {
          return selection && parseFloat(selection.price);
        })
        .reduce((total, num) => total + num);

      mobileNextStepsButton = null;
      screenToDisplay = (
        <ConfiguratorHome
          productKey={productKey}
          productName={productName}
          isFitLocked={confirmedFitShapeCode}
          selectedBody={selectedBody}
          selectedFit={selectedFit}
          categorySettings={categorySettings}
          categoriesToShow={categoriesToShow}
          userSelections={userSelections}
          setCurrentScreen={setCurrentScreen}
          canOpenAttributes={selectableOptionsTableLoaded}
          currencyCode={currencyCode}
          currencySymbol={currencySymbol}
          itemCost={itemCost || 0}
          authenticityToken={authenticityToken}
          lineItemId={existingLineItems.id}
          startingQuantity={startingQuantity}
          fitOptions={fitOptions}
          monogramInitials={transformedMonogramInitials}
          monogramCategories={monogramCategories}
          monogramSelections={monogramSelections}
          monogramFullySelected={monogramFullySelected}
          hidePayment={hidePayment}
          showBodySelector={showBodySelector}
          productExternalId={productExternalId}
          cartSettings={cartSettings}
          contrastStitchingEnabled={contrastStitchingEnabled}
          isItemClone={isItemClone}
          canAddToBasket={canAddToBasket || canAddToAttendantBasket}
          isAttendant={isAttendant}
        />
      );
      break;
    }
    case 'body': {
      mobileNextStepsButton = (
        <NextStepButton
          nextAttribute="fit"
          nextAttributeName="Fit"
          previousAttribute={null}
          isDisabled={!!selectableOptionsTable[nextAttribute]}
          setCurrentScreen={setCurrentScreen}
          productName={productName}
          canAddToBasket={canAddToBasket}
        />
      );
      screenToDisplay = (
        <BodySelectionPanel
          productName={productName}
          nextAttribute="fit"
          nextAttributeName="Fit"
          previousAttribute={null}
          selectedBody={selectedBody}
          iframeUrl={bodySelectorIframeUrl}
          updateSelectedBody={updateSelectedBody}
          setCurrentScreen={setCurrentScreen}
          currencySymbol={currencySymbol}
          canAddToBasket={canAddToBasket}
        />
      );
      break;
    }
    case 'fit': {
      const resetSelections = () => {
        setUserSelections(defaultUserSelection);
        setExistingLineItems({
          fit: selectedFit,
          id: existingLineItems.id,
          ...defaultUserSelection
        });
        setIsDefaultImageActive(false);
      };
      const isNextDisabled = !selectableOptionsTable[nextAttribute];
      mobileNextStepsButton = (
        <NextStepButton
          nextAttribute={nextAttribute}
          nextAttributeName={nextAttributeName}
          previousAttribute={
            showBodySelector === SHOW_BODY_SELECTOR.SELECTION ? 'body' : null
          }
          isDisabled={isNextDisabled}
          setCurrentScreen={setCurrentScreen}
          productName={productName}
          canAddToBasket={canAddToBasket}
        />
      );
      screenToDisplay = (
        <FitSelectionPanel
          productName={productName}
          nextAttribute={nextAttribute}
          nextAttributeName={nextAttributeName}
          isNextDisabled={isNextDisabled}
          previousAttribute={
            showBodySelector === SHOW_BODY_SELECTOR.SELECTION ? 'body' : null
          }
          selectedFit={selectedFit}
          setSelectedFit={setSelectedFit}
          setCurrentScreen={setCurrentScreen}
          fitOptions={fitOptions}
          currencySymbol={currencySymbol}
          resetSelections={resetSelections}
          canAddToBasket={canAddToBasket}
        />
      );
      break;
    }
    case 'monogram': {
      const isDisabled = wasInMonogramSection && !monogramFullySelected;

      mobileNextStepsButton = (
        <NextStepButton
          nextAttribute={nextAttribute}
          nextAttributeName={nextAttributeName}
          previousAttribute={previousAttribute}
          isDisabled={isDisabled}
          setCurrentScreen={setCurrentScreen}
          productName={productName}
          canAddToBasket={canAddToBasket}
        />
      );
      screenToDisplay = (
        <MonogramSelectionPanel
          key={currentScreen}
          productName={productName}
          currencySymbol={currencySymbol}
          nextAttribute={nextAttribute}
          nextAttributeName={nextAttributeName}
          setWasInMonogramSection={setWasInMonogramSection}
          previousAttribute={previousAttribute}
          setCurrentScreen={setCurrentScreen}
          isDisabled={isDisabled}
          selectableOptionsTable={selectableOptionsTable}
          setSelectedAttributeFunctions={setSelectedAttributeFunctions}
          userSelections={userSelections}
          clearMonogram={clearMonogram}
          monogramError={monogramError}
          monogramCategories={monogramCategories}
          monogramStylesSpecs={MONOGRAM_STYLES_SPECS}
          selectedMonogramStyleSpecs={selectedMonogramStyleSpecs}
          monogramInitials={monogramInitials}
          setMonogramInitials={setMonogramInitials}
          canAddToBasket={canAddToBasket}
        />
      );
      break;
    }
    case 'contrast_buttonhole': {
      mobileNextStepsButton = (
        <NextStepButton
          nextAttribute={nextAttribute}
          nextAttributeName={nextAttributeName}
          previousAttribute={previousAttribute}
          setCurrentScreen={setCurrentScreen}
          productName={productName}
          canAddToBasket={canAddToBasket}
        />
      );
      screenToDisplay = (
        <ButtonholeSelectionPanel
          key={currentScreen}
          productName={productName}
          currencySymbol={currencySymbol}
          nextAttribute={nextAttribute}
          nextAttributeName={nextAttributeName}
          previousAttribute={previousAttribute}
          setCurrentScreen={setCurrentScreen}
          isDisabled={false}
          selectableOptionsTable={selectableOptionsTable}
          setSelectedAttributeFunctions={setSelectedAttributeFunctions}
          userSelections={userSelections}
          canAddToBasket={canAddToBasket}
        />
      );
      break;
    }
    case 'contrast_stitching': {
      const actualNextAttribute =
        nextAttribute === 'monogram' ? 'monogram_initials' : nextAttribute;
      const isNextDisabled =
        // if the actualNextAttribute/nextAttribute is undefined, this means the contrast stitching
        // is the last option and in that case, it shouldn't look in the selectableOptionsTable
        (actualNextAttribute && !selectableOptionsTable[actualNextAttribute]) ||
        // Check if the checkbox is enabled but all of the categories haven't been selected
        (contrastStitchingEnabled &&
          !CONTRAST_STITCHING_CATEGORIES_ARRAY.every((category) =>
            hasProp(userSelections, category) ?
              userSelections[category] !== null
            : true
          ));

      mobileNextStepsButton = (
        <NextStepButton
          nextAttribute={nextAttribute}
          nextAttributeName={nextAttributeName}
          previousAttribute={previousAttribute}
          isDisabled={isNextDisabled}
          setCurrentScreen={setCurrentScreen}
          productName={productName}
          canAddToBasket={canAddToBasket}
        />
      );
      screenToDisplay = (
        <ContrastStitchingSelectionPanel
          key={currentScreen}
          productName={productName}
          currencySymbol={currencySymbol}
          nextAttribute={nextAttribute}
          nextAttributeName={nextAttributeName}
          previousAttribute={previousAttribute}
          setCurrentScreen={setCurrentScreen}
          isDisabled={isNextDisabled}
          selectableOptionsTable={selectableOptionsTable}
          unselectableOptionsTable={unselectableOptionsTable}
          setSelectedAttributeFunctions={setSelectedAttributeFunctions}
          userSelections={userSelections}
          enabled={contrastStitchingEnabled}
          setEnabled={setContrastStitchingEnabled}
          wasInContrastStitchingSection={wasInContrastStitchingSection}
          setWasInContrastStitchingSection={setWasInContrastStitchingSection}
          canAddToBasket={canAddToBasket}
        />
      );
      break;
    }
    default:
      if (categorySettings[currentScreen]?.selection_panel_layout === 'grid') {
        screenToDisplay = (
          <FabricSelectionPanel
            fabrics={selectableOptions}
            productName={productName}
            attributeName={categorySettings[currentScreen].display_name}
            hasMoreInfo={currentScreen === 'fabric'}
            nextAttribute={nextAttribute}
            nextAttributeName={nextAttributeName}
            previousAttribute={previousAttribute}
            setSelectedFit={setSelectedFit}
            setCurrentScreen={setCurrentScreen}
            selectedAttribute={currentSelectedAttribute}
            setSelectedAttribute={currentSetSelectedAttribute}
            currencySymbol={currencySymbol}
            hidePayment={hidePayment}
            showFabricDetail={showFabricDetail}
            canAddToBasket={canAddToBasket}
          />
        );
        mobileNextStepsButton = (
          <NextStepButton
            nextAttribute={nextAttribute}
            nextAttributeName={nextAttributeName}
            previousAttribute={previousAttribute}
            isDisabled={!currentSelectedAttribute}
            setCurrentScreen={setCurrentScreen}
            productName={productName}
            canAddToBasket={canAddToBasket}
          />
        );
      } else {
        mobileNextStepsButton = (
          <NextStepButton
            nextAttribute={nextAttribute}
            nextAttributeName={nextAttributeName}
            previousAttribute={previousAttribute}
            isDisabled={!currentSelectedAttribute}
            setCurrentScreen={setCurrentScreen}
            productName={productName}
            canAddToBasket={canAddToBasket}
          />
        );
        screenToDisplay = (
          <AttributeSelectionPanel
            key={currentScreen}
            productName={productName}
            attributeName={categorySettings[currentScreen].display_name}
            nextAttribute={nextAttribute}
            nextAttributeName={nextAttributeName}
            previousAttribute={previousAttribute}
            setCurrentScreen={setCurrentScreen}
            selectedAttribute={currentSelectedAttribute}
            setSelectedAttribute={currentSetSelectedAttribute}
            currencySymbol={currencySymbol}
            options={selectableOptions}
            canAddToBasket={canAddToBasket}
          />
        );
      }
      break;
  }

  let imagePreviewScreenToDisplay;

  switch (currentScreen) {
    case 'body':
    case 'fit':
      imagePreviewScreenToDisplay = (
        <FitImagePreview
          productKey={productKey}
          selectedFit={selectedFit}
          remoteImagesPath={remoteImagesPath}
          resizedRemoteImagesPath={resizedRemoteImagesPath}
          resizedImageExtension={resizedImageExtension}
          brandCode={brandCode}
          showCustomPreviewImage={showCustomPreviewImage}
        />
      );
      break;
    case 'fabric':
      imagePreviewScreenToDisplay = (
        <ModelImagePreview
          productKey={productKey}
          selectedFabric={userSelections['fabric']}
          remoteImagesPath={remoteImagesPath}
          brandCode={brandCode}
          showCustomPreviewImage={showCustomPreviewImage}
        />
      );
      break;
    default:
      if (!userSelections['fabric'] || isDefaultImageActive) {
        imagePreviewScreenToDisplay = (
          <ModelImagePreview
            productKey={productKey}
            selectedFabric={null}
            remoteImagesPath={remoteImagesPath}
            brandCode={brandCode}
            showCustomPreviewImage={showCustomPreviewImage}
          />
        );
      } else if (hasLaydownPreview) {
        imagePreviewScreenToDisplay = (
          <AttributesImagePreview
            productKey={productKey}
            userSelections={userSelections}
            categories={categories}
            monogramCategories={monogramCategories}
            remoteImagesPath={remoteImagesPath}
            resizedRemoteImagesPath={resizedRemoteImagesPath}
            resizedImageExtension={resizedImageExtension}
            categoriesWithoutPreview={categoriesWithoutPreview}
            monogramInitials={transformedMonogramInitials}
            selectedMonogramStyleSpecs={selectedMonogramStyleSpecs}
            defaultMonogramColor={defaultMonogramColor}
            monogramError={monogramError}
            onImageLoadError={triggerDefaultImage}
          />
        );
      } else {
        imagePreviewScreenToDisplay = (
          <ModelImagePreview
            productKey={productKey}
            selectedFabric={userSelections['fabric']}
            remoteImagesPath={remoteImagesPath}
            brandCode={brandCode}
            showCustomPreviewImage={showCustomPreviewImage}
          />
        );
      }
  }

  const fabricOptions = selectableOptionsTable['fabric'];

  return (
    <div className="h-100">
      {showBodySelector === SHOW_BODY_SELECTOR.POPUP && (
        <PopupBodySelector
          selectedBody={selectedBody}
          iframeUrl={bodySelectorIframeUrl}
          updateSelectedBody={updateSelectedBody}
          isEssOnly={view === 'ess-only'}
          authenticityToken={authenticityToken}
          configuredItemIds={popupQueryParamConfiguredItemIds}
          cartApi={cartSettings.cartApi}
          essQueryParams={essQueryParams}
        />
      )}
      {!(isIframeIntegrated && view === 'ess-only') && (
        <>
          <div className="row h-100">
            <div className="col-md-6 col-sm-12 fc-right-preview-container">
              {imagePreviewScreenToDisplay}
            </div>
            {screenToDisplay}
          </div>
          <div className="d-md-none fc-mobile-next-steps">
            {mobileNextStepsButton}
          </div>
          {fabricOptions &&
            fabricOptions.map((fabric) => (
              <FabricDescriptionModal
                key={fabric.id}
                fabric={fabric}
                currencySymbol={currencySymbol}
              />
            ))}
        </>
      )}
    </div>
  );
}

export function useLoadExistingFit(
  fitOptions,
  existingFitShapeCode,
  setSelectedFit
) {
  useEffect(() => {
    if (!fitOptions || !existingFitShapeCode) return;

    const initialFit = fitOptions.find(
      (option) => option.gpp_shape_code === existingFitShapeCode
    );
    if (initialFit) {
      setSelectedFit(initialFit);
    }
  }, [existingFitShapeCode, fitOptions, setSelectedFit]);
}

//for monogram

// Run this example by adding <%= javascript_pack_tag 'Configurator_react' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>Configurator React</div> at the bottom
// of the page.
