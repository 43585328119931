import React from 'react';
import cx from 'classnames';

export default function OrderTotals({
  currencySymbol,
  itemCost,
  quantity,
  setQuantity,
  isLimitedQuantity,
  hidePayment,
  isAttendant
}) {
  const [isInvalidQuantity, setIsInvalidQuantity] = React.useState(false);
  const totalCost = quantity ? quantity * itemCost : 0;
  const quantityClassNames = cx(
    'fc-quantity-value',
    (!isLimitedQuantity || isAttendant) && 'fc-quantity-value-enabled'
  );
  const handleQuantityChange = (e) => {
    const newQuantity = parseInt(e.target.value);
    if (newQuantity < 1) {
      setQuantity(1);
      setIsInvalidQuantity(false);
    } else if (newQuantity > 99) {
      setIsInvalidQuantity(false);
      setQuantity(99);
    } else {
      setIsInvalidQuantity(false);
      setQuantity(isNaN(newQuantity) ? '' : newQuantity);
    }
  };

  return (
    <div>
      {isInvalidQuantity && (
        <div
          className="fc-quantity-warning-container alert alert-dismissible fade show"
          role="alert"
        >
          <button
            type="button"
            className="close fc-quantity-warning-dismiss"
            aria-label="Close"
            onClick={() => setIsInvalidQuantity(false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <span className="fc-quantity-warning-text">
            Note: We only allow one product per category for first time orders.
            This ensures you get exactly the right fit and that all of your
            expectations are met. Your next order will allow the purchase of
            multiple items.
          </span>
        </div>
      )}
      <div className="row no-gutters fc-price-quantity-row">
        <div className="col fc-quantity">
          <span className="fc-quantity-title">Quantity</span>
          <span className={quantityClassNames}>
            <input
              type="number"
              min="1"
              value={quantity}
              className="fc-quantity-input"
              onBlur={() => {
                if (isNaN(Number(quantity))) {
                  setQuantity(1);
                }
              }}
              onChange={handleQuantityChange}
            />
          </span>
        </div>
        <div className="col fc-price">
          {!hidePayment && (
            <>
              {currencySymbol}
              {totalCost}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
